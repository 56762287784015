// State
export const state = () => ({
	report: {},
	loading: false,
	deposit: [],
	withdrawal: [],
	depositPromotion: [],
	searchDepositPromotion: [],
	depositConfirm: [],
	withdrawalPromotion: [],
	withdrawalConfirm: [],
	playerBets: {},
	playersWithoutTopup: { data: [] },
	playerInactiveTopup: {},
	subBetHistories: [],
	deposits: {},
	withdrawals: {},
	promotionOnDeposit: [],

	// cancel token
	reportCancelToken: {}
})

// Actions
export const actions = {
	// get deposit with promotion
	async depositPromotion({ commit, state }, params) {
		commit('setReportCancelToken', { depositPromotion: this.$axios.CancelToken.source() })
		commit('loading', true)
		const response = await this.$axios
			.get(`/statistics/summary/deposit/promotions`, {
				params,
				cancelToken: state.reportCancelToken.depositPromotion.token
			})
			.then((response) => {
				commit('setDepositPro', response.data)
				commit('loading', false)
				return response.data
			}).catch((e) => {
				commit('loading', false)
				return []
			})
		return response
	},
	searchDepositPromotion({ commit, state }, params) {
		commit('setReportCancelToken', { searchDepositPromotion: this.$axios.CancelToken.source() })
		commit('loading', true)
		this.$axios
			.get(`/statistics/summary/deposit/promotions`, {
				params,
				cancelToken: state.reportCancelToken.searchDepositPromotion.token
			})
			.then((response) => {
				commit('searchDepositPromotion', response.data)
				commit('loading', false)
			}).catch((e) => {
				commit('loading', false)
			})
	},
	async playerBets({ commit, state }, data) {
		commit('setReportCancelToken', { playerBets: this.$axios.CancelToken.source() })
		const params = { ...data }
		if (params && params.game_type_uuid && params.game_type_uuid.length > 0) {
			params.game_type_uuid = JSON.stringify(params.game_type_uuid)
		} else delete params.game_type_uuid
		if (params && params.game_provider_uuid && params.game_provider_uuid.length > 0) {
			params.game_provider_uuid = JSON.stringify(params.game_provider_uuid)
		} else delete params.game_provider_uuid
		commit('loading', true)

		await this.$axios
			.get(`/bet-management/bet-history/players`, {
				params,
				cancelToken: state.reportCancelToken.playerBets.token
			})
			.then((response) => {
				commit('setPlayerBets', response.data)
				commit('loading', false)
			})
			.catch((e) => {
				commit('loading', false)
			})
	},

	// get players without topup
	async getPlayersWithoutTopup({ commit, state }, params) {
		commit('setReportCancelToken', { getPlayersWithoutTopup: this.$axios.CancelToken.source() })
		commit('loading', true)
		await this.$axios
			.get(`/statistics/players/players-without-topup`, {
				params,
				cancelToken: state.reportCancelToken.getPlayersWithoutTopup.token
			})
			.then((response) => {
				commit('setPlayerWithoutTopup', response.data)
				commit('loading', false)
			})
			.catch((e) => {
				commit('loading', false)
			})
	},
	async getSubBet({ commit }, id) {
		commit('loading', true)
		await this.$axios
			.get(`/statistics/bet-history/sub-bet/${id}`)
			.then((response) => {
				commit('setSubBetHistories', response.data)
				commit('loading', false)
			})
			.catch((e) => {
				commit('loading', false)
			})
	},
	async deposits({ commit, state }, params) {
		commit('setReportCancelToken', { deposits: this.$axios.CancelToken.source() })
		commit('loading', true)
		await this.$axios
			.get(`/statistics/summary/deposit`, {
				params,
				cancelToken: state.reportCancelToken.deposits.token
			})
			.then((response) => {
				commit('deposits', response.data)
				commit('loading', false)
			})
			.catch((e) => {
				commit('loading', false)
			})
	},
	async withdrawals({ commit, state }, params) {
		commit('setReportCancelToken', { withdrawals: this.$axios.CancelToken.source() })
		commit('loading', true)
		await this.$axios
			.get(`/statistics/summary/withdrawal`, {
				params,
				cancelToken: state.reportCancelToken.withdrawals.token
			})
			.then((response) => {
				commit('withdrawals', response.data)
				commit('loading', false)
			})
			.catch((e) => {
				commit('loading', false)
			})
	},
	async promotionOnDeposit({ commit, state }, params) {
		commit('setReportCancelToken', { promotionOnDeposit: this.$axios.CancelToken.source() })
		commit('loading', true)
		await this.$axios
			.get(`/statistics/summary/withdrawal/promotions`, {
				params,
				cancelToken: state.reportCancelToken.promotionOnDeposit.token
			})
			.then((response) => {
				commit('promotionOnDeposit', response.data)
				commit('loading', false)
			})
	},
	async getMissingPlayerReport({ commit, state }, params) {
		commit('setReportCancelToken', { getMissingPlayerReport: this.$axios.CancelToken.source() })
		const response = await this.$axios
			.get(`/statistics/players/export-players-without-topup`, {
				params,
				cancelToken: state.reportCancelToken.getMissingPlayerReport.token
			})
			.then((response) => {
				return response.data
			})
			.catch((_) => {
				return []
			})
		return response;
	},
}

// Mutations
export const mutations = {
	setReport(state, data) {
		state.report = data
	},

	loading(state, type) {
		state.loading = type
	},
	setDeposit(state, data) {
		state.deposit = data
	},
	setDepositPro(state, data) {
		state.depositPromotion = data
	},

	setWithdrawal(state, data) {
		state.withdrawal = data
	},
	setWithdrawalCon(state, data) {
		state.withdrawalConfirm = data
	},
	setPlayerBets(state, data) {
		state.playerBets = data
	},
	setPlayerWithoutTopup(state, data) {
		state.playersWithoutTopup = data
	},
	setPlayerInactiveTopup(state, data) {
		state.playerInactiveTopup = data
	},
	setSubBetHistories(state, data) {
		state.subBetHistories = data
	},
	deposits(state, data) {
		state.deposits = data
	},
	withdrawals(state, data) {
		state.withdrawals = data
	},
	searchDepositPromotion(state, data) {
		state.searchDepositPromotion = data
	},
	promotionOnDeposit: (state, v) => {
		state.promotionOnDeposit = v
	},
	setReportCancelToken: (state, data) => {
		const oldData = { ...state.reportCancelToken }
		state.reportCancelToken = {
			...oldData, ...data
		}
	}
}