import { PLAYER_REGEX } from '~/utils/constants'

// State
export const state = () => ({
    players: [],
    playerIds: [],
    playerNames: [],
    phones: [],
    nicknames: [],
    loadingPlayers: false,
    searchingCancelToken: {},
})

// Actions
export const actions = {
    players({ commit }, params) {
        this.$axios
            .get(`/csl-master/operator/players`, { params })
            .then((response) => {
                commit('players', response.data)
            })
            .catch((e) => {})
    },
    async playerSearch({ commit, state }, data) {
        const params = JSON.parse(JSON.stringify(data))
        if (params?.player_id) {
            params.player_id = params?.player_id?.toLowerCase()
            const isMatch = PLAYER_REGEX
            if (!isMatch.test(params?.player_id)) {
                return null
            }
        }
        if (state.searchingCancelToken.searchingPlayerId) {
            state.searchingCancelToken.searchingPlayerId.cancel('')
        }
        commit('setSearchingCancelToken', {
            searchingPlayerId: this.$axios.CancelToken.source(),
        })

        commit('loadingPlayers', true)
        const response = await this.$axios
            .get(`/csl-master/operator/players`, {
                params,
                cancelToken: state.searchingCancelToken.searchingPlayerId.token,
            })
            .then((response) => {
                // data is the response from API
                // type is the types of searching [username, playerId, playerName, phone]
                commit('playerSearch', { data: response.data, type: params })
                commit('loadingPlayers', false)
                return response.data
            })
            .catch((e) => {
                commit('loadingPlayers', false)
            })
        return response
    },
}

// Mutations
export const mutations = {
    players(state, data) {
        state.players = data
    },
    playerSearch(state, data) {
        const { type } = data
        if (type.player_id) {
            state.playerIds = [...data.data]
        } else if (type.name) {
            state.playerNames = [...data.data]
        } else if (type.phone) {
            state.phones = [...data.data]
        } else if (type.nickname) {
            state.nicknames = [...data.data]
        }
    },
    loadingPlayers(state, type) {
        state.loadingPlayers = type
    },
    // cancel token
    setSearchingCancelToken: (state, data) => {
        const oldData = { ...state.searchingCancelToken }
        state.searchingCancelToken = {
            ...oldData,
            ...data,
        }
    },
}
